<template>
    <section :class="CSSClasses" class="tier">
        <template v-if="isFullBleed">
            <div v-if="eyebrow" class="inner">
                <div class="heading">
                    <Eyebrow :hasUnderline="true" class="eyebrow" :isCentered="centerEyebrow">{{ eyebrow }}</Eyebrow>
                </div>
            </div>
            <slot></slot>
        </template>

        <div v-else class="inner">
            <div v-if="eyebrow" class="heading">
                <Eyebrow :hasUnderline="true" class="eyebrow" :isCentered="centerEyebrow">{{ eyebrow }}</Eyebrow>
            </div>

            <slot></slot>
        </div>
    </section>
</template>

<script setup>
const props = defineProps({
    background: {
        type: String,
        default: 'white',
        validator: (value) => {
            return ['white', 'grey', 'blue', 'darkblue'].includes(value);
        },
    },
    topPadding: {
        type: String,
        default: 'standard',
        validator: (value) => {
            return ['standard', 'extra', 'negative'].includes(value);
        },
    },
    bottomPadding: {
        type: String,
        default: 'standard',
        validator: (value) => {
            return ['standard', 'extra', 'negative'].includes(value);
        },
    },
    eyebrow: {
        type: String,
        default: '',
    },
    centerEyebrow: {
        type: Boolean,
        default: false,
    },
    isFullBleed: {
        type: Boolean,
        default: false,
    },
    isCenteredContent: {
        type: Boolean,
        default: false,
    },
});

provide('colorContext', props.background);
provide('tierTheme', props.background === 'blue' || props.background === 'darkblue' ? 'dark' : 'light');

const CSSClasses = computed(() => {
    return [
        `top-padding-${props.topPadding}`,
        `bottom-padding-${props.bottomPadding}`,
        `background-${props.background}`,
        props.isFullBleed ? 'full-bleed' : null,
        props.centerEyebrow ? 'center-eyebrow' : null,
        props.isCenteredContent ? 'center-content' : null,
    ];
});
</script>

<style lang="scss" scoped>
.tier {
    &:not(.full-bleed) {
        &.top-padding-standard {
            padding-top: vertical-space(4);
        }

        &.bottom-padding-standard {
            padding-bottom: vertical-space(4);
        }

        &.top-padding-extra {
            padding-top: vertical-space(8);
        }

        &.bottom-padding-extra {
            padding-bottom: vertical-space(8);
        }
    }

    &.background-white {
        background: color(blanc);
    }

    &.background-grey {
        background: color(offblanc);
    }

    &.background-blue {
        background: color(blue, dark);
    }
    &.background-darkblue {
        background: color(blue, dark);
        .eyebrow {
            color: color(blanc, default, 0.7);
        }
    }
}

.inner {
    @include content-section;
    .center-content & {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.heading {
    @include content-area-padding;
    .center-eyebrow.eyebrow {
        text-align: center;
    }
}
</style>
